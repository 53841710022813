/* eslint-disable complexity */
import React, { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AdminTable from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import EventsApprovalModal from 'src/components/events-approval-modal/events-approval-modal';
import { UPDATE_SANCTION_STATUS } from 'src/components/events/events-queries';
import * as styles from 'src/components/events/events.module.less';
import ExpandedMenu from 'src/components/expanded-menu/expanded-menu';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import RequestChangesModal from 'src/components/request-changes-modal/request-changes-modal';
import Spinner from 'src/components/spinner/spinner';
import { GetEvents } from 'src/graphql-types/GetEvents';
import { EventTypeEnum, TournamentFeePaymentSatus } from 'src/graphql-types/globalTournamentTypes';
import { UpdateSanction, UpdateSanctionVariables } from 'src/graphql-types/UpdateSanction';
import useApprovalToggle from 'src/hooks/use-approval-toggle';
import { useIsSanctioningBody } from 'src/utils/auth';
import getSortedEvents from 'src/utils/helper/events';

import { useEventTableColumns } from './hooks/useEventTableColumns';

interface EventsTableProps {
  tournamentId?: string;
  data?: GetEvents;
  loading?: boolean;
}

const EventsTable: React.FC<EventsTableProps> = ({ tournamentId, data, loading }) => {
  const orgId = useOrgId();
  const isSanctioningBody = useIsSanctioningBody(tournamentId);

  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [updateSanctionStatus, { loading: updating, error }] = useMutation<UpdateSanction, UpdateSanctionVariables>(
    UPDATE_SANCTION_STATUS,
    { client: tournamentsClient },
  );

  const requiresApproval = data?.tournament?.sanctionStatus === 'SUBMITTED';
  const approvalEnabled = (requiresApproval || editMode) && isSanctioningBody;

  const events = useMemo(() => getSortedEvents(data?.tournament?.events), [data?.tournament?.events]);

  const {
    updateApproval,
    publishable,
    getApprovedIds,
    approvedStates,
    approveAll,
    declineAll,
    reset,
    eventApprovedStates,
    hasChanges,
  } = useApprovalToggle(events);

  const willAttemptCharge =
    !!data?.tournament &&
    !!data?.tournament.tournamentFeePayment?.paymentMethod?.id &&
    data?.tournament?.tournamentFeePayment?.status === TournamentFeePaymentSatus.PENDING;

  const submitEvents = useCallback(
    async (message?: string) => {
      const { approved, declined } = getApprovedIds();
      const { data } = await updateSanctionStatus({
        variables: {
          id: tournamentId,
          approved: approved.map((id) => ({ eventId: id })),
          declined: declined.map((id) => ({ eventId: id })),
          orgId,
          message,
          processPayment: willAttemptCharge,
        },
      });
      // we continue showing the dialogue if the charge failed, user may try again
      if (
        data?.updateTournamentSanctionStatus.tournamentFeePayment?.status !== TournamentFeePaymentSatus.CHARGE_FAILED
      ) {
        setEditMode(false);
      }
      return data?.updateTournamentSanctionStatus;
    },
    [getApprovedIds, updateSanctionStatus, tournamentId, orgId, willAttemptCharge],
  );

  const { approvedCount, declinedCount } = useMemo(() => {
    const { approved, declined } = getApprovedIds();
    return { approvedCount: approved.length, declinedCount: declined.length };
  }, [getApprovedIds]);

  const handleCancel = () => {
    setShowModal(false);
  };

  const requestChanges = () => {
    setShowModal(true);
  };

  const onCancelEdit = useCallback(() => {
    setEditMode(false);
    reset();
  }, [reset]);

  const errorMessage =
    error?.graphQLErrors[0]?.message === 'sanctioningOrganisationHasNoDefaultAcccount'
      ? t('no default account')
      : error?.message;

  const cols = useEventTableColumns(
    approvalEnabled,
    approvedStates,
    editMode,
    eventApprovedStates,
    updateApproval,
    events ? events?.every((e) => e.division.Enum === EventTypeEnum.TEAM) : false,
  );

  return (
    <Panel
      title={
        <span>
          {t('events')}
          <span className={styles.eventsSubtitle}>{t('events subtitle')}</span>
        </span>
      }
      headerEndContent={
        <>
          {approvalEnabled ? (
            <>
              {editMode ? (
                <Button level="secondary" onClick={onCancelEdit}>
                  {t('cancel')}
                </Button>
              ) : (
                <>
                  {requiresApproval && (
                    <RequestChangesModal
                      tournament={data?.tournament}
                      orgId={orgId}
                      showModal={showModal}
                      cancelButton={{
                        props: {
                          level: 'tertiary',
                          spacing: { margins: { sm: 'right' } },
                          onClick: handleCancel,
                        },
                        content: <>{t('cancel')}</>,
                      }}
                      onVisibilityChange={() => setShowModal(!showModal)}
                    />
                  )}
                  <ExpandedMenu
                    items={[
                      { key: 'approve', label: t('mark all approved'), onClick: approveAll },
                      { key: 'decline', label: t('mark all declined'), onClick: declineAll },
                      { key: 'changes', label: t('request changes'), onClick: requestChanges },
                      { key: 'clear', label: t('clear all'), onClick: reset },
                    ]}
                    buttonText={t('approval options')}
                    buttonIcon
                    iconName="sm-down"
                    anchorElement="button"
                    buttonProps={{ disabled: !isSanctioningBody }}
                  />
                </>
              )}
              {tournamentId && (
                <EventsApprovalModal
                  buttonText={editMode ? t('update') : t('submit')}
                  loading={updating}
                  disableShow={editMode ? !hasChanges : !publishable}
                  submitEvents={submitEvents}
                  errorMessage={errorMessage}
                  tournamentId={tournamentId}
                  approved={approvedCount}
                  declined={declinedCount}
                  fee={data?.tournament.tournamentFee}
                  willAttemptCharge={willAttemptCharge}
                  previousChargeFailed={
                    data?.tournament?.tournamentFeePayment?.status === TournamentFeePaymentSatus.CHARGE_FAILED
                  }
                />
              )}
            </>
          ) : (
            !loading &&
            isSanctioningBody && (
              <Button level="secondary" onClick={() => setEditMode(true)}>
                <Icon name="sm-edit" className={styles.editIcon} />
                {t('edit approval status')}
              </Button>
            )
          )}
        </>
      }
    >
      {loading && <Spinner />}
      {data && !data.tournament && t('no tournament err')}
      {events && <AdminTable columns={cols} data={events} />}
    </Panel>
  );
};

export default EventsTable;
